function lazyLoad(view) {
  return () => import(`./${view}.vue`);
}

// Note this is pulled into its own top-level route to avoid conflicts
// with onSnapshot in the other pages.
export default {
  path: "/note-board/:noteBoardId",
  component: lazyLoad("NoteBoardRoot"),
  props: true,
  children: [
    {
      path: "",
      name: "NoteBoardPortal",
      component: lazyLoad("NoteBoardPortal"),
      props: true,
    },
    {
      path: "home",
      name: "NoteBoardView",
      component: lazyLoad("NoteBoardView"),
      props: true,
    },
  ],
};
