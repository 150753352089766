import { auth } from "@learnics/services/src/analyticsConfig";

function lazyLoad(view) {
  return () => import(`@/views/admin/${view}.vue`);
}
export default {
  path: "/admin",
  // name: "AdminRoot",
  component: lazyLoad("AdminRoot"),

  beforeEnter: async (to, from, next) => {
    const currentUser = await auth.currentUser?.getIdTokenResult(true);
    if (currentUser.claims.admin) {
      next();
    } else {
      next("/");
    }
  },
  children: [
    {
      path: "",
      redirect: "./users",
    },
    {
      path: "users",
      name: "AdminUsersView",
      component: lazyLoad("AdminUsersView"),
    },
    {
      path: "assignments",
      name: "AdminAssignmentsView",
      component: lazyLoad("AdminAssignmentsView"),
    },
    {
      path: "organizations",
      name: "AdminOrganizationsView",
      component: lazyLoad("AdminOrganizationsView"),
    },
    {
      path: "emailTests",
      name: "AdminEmailTestsView",
      component: lazyLoad("AdminEmailTestsView"),
    },
    {
      path: "misc",
      name: "AdminMiscView",
      component: lazyLoad("AdminMiscView"),
    },
    {
      path: "extensionInstallations",
      name: "AdminExtensionInstallationsView",
      component: lazyLoad("AdminExtensionInstallationsView"),
    },
  ],
};
