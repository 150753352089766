import Vue from "vue";
import ExtensionInstallationService from "@learnics/services/src/extensionInstallation";

export const state = {
  extensionInstallations: {},
  extensionInstallationsLoadedForUser: new Set(),
};

export const getters = {
  extensionInstallations: (state) => state.extensionInstallations,
  extensionInstallationsLoadedForUser: (state) =>
    state.extensionInstallationsLoadedForUser,
};

export const mutations = {
  setExtensionInstallation: (
    state,
    { compositeId, newVal, oldVal, forced }
  ) => {
    const { id } = compositeId;
    if (newVal) {
      Vue.set(state.extensionInstallations, id, newVal);
    } else Vue.delete(state.extensionInstallations, id);
  },

  addExtensionInstallationsLoadedForUser: (state, userId) => {
    const newSet = new Set(state.extensionInstallationsLoadedForUser);
    newSet.add(userId);
    Vue.set(state, "extensionInstallationsLoadedForUser", newSet);
  },

  removeExtensionInstallationsLoadedForUser: (state, userId) => {
    const newSet = new Set(state.extensionInstallationsLoadedForUser);
    newSet.delete(userId);
    Vue.set(state, "extensionInstallationsLoadedForUser", newSet);
  },
};

export const actions = {
  /**
   * Load one or more Notes into the store.
   *
   * @param state
   * @param assignment
   */
  async loadExtensionInstallationsForUsers(
    { state, dispatch, commit, rootState },
    args = { forced: false, userIds: [] }
  ) {
    const userIds = [...(args.userIds || [])];
    console.log(
      "Attempting to load extension installations for users: ",
      userIds
    );
    for (let userId of userIds) {
      await dispatch("loadExtensionInstallationsForUser", {
        userId,
        forced: !!args.forced,
      });
    }
  },

  /**
   * Load a Note into the store.
   *
   */

  async loadExtensionInstallationsForUser({ state, commit, rootState }, args) {
    const { userId } = args;

    if (!state.extensionInstallationsLoadedForUser.has(userId) || args.forced) {
      try {
        const extensionInstallations =
          await ExtensionInstallationService.getInstallationsByUserId(userId);
        Object.keys(extensionInstallations).forEach((id) => {
          console.log(
            "Loading extension installation for user: ",
            userId,
            extensionInstallations[id]
          );
          commit("setExtensionInstallation", {
            compositeId: { id },
            newVal: extensionInstallations[id],
            forced: args.forced,
          });
        });
        commit("addExtensionInstallationsLoadedForUser", userId);
      } catch (e) {
        console.error(
          "Failed to load extension installations for user: ",
          userId,
          e
        );
      }
    }
  },
};
